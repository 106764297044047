import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

const Loader = () => {
  const { loading } = useSelector((state) => state.general);
  return (
    <Backdrop
      open={loading.length !== 0}
      sx={{
        zIndex: 9999,
        color: '#fff',
      }}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default Loader;
