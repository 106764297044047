/* eslint-disable import/no-cycle */
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { capitalize, getPageTitle, getCookie } from './general';

/* 

useAccessControl hook takes a role as parameter and returns a boolean value based on logged in user's role.

if no role is passed then it will return true for all users, and 
if the access is restricted to a specific role then you need to pass that role

*/

export const useAccessControl = () => {
  const getUserRoles = getCookie('userRoles');
  const userRoles = JSON.parse(getUserRoles || '[]');
  const allowed = (role) => {
    const conditional = !role
      ? true
      : Boolean(userRoles?.filter((r) => r === role).length);

    return conditional;
  };
  return allowed;
};

export const useGApageview = () => {
  const pageTitle = getPageTitle();
  useEffect(() => {
    const allowGoogleAnalytics = process.env.REACT_APP_GA_MEASUREMENT_ID;
    if (allowGoogleAnalytics) {
      ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname,
        title: capitalize(pageTitle),
      });
    }
  }, [pageTitle]);
};

export default { useAccessControl, useGApageview };
