import React from 'react';

const defaultHeaderConfig = {
  logo: false,
  home: false,
  title: '',
};
export const privateRoutes = [
  // {
  //   Path: '/',
  //   Component: React.lazy(() => import('../container/Home')),
  //   meta: {
  //     name: 'Home page',
  //   },
  //   headerConfig: {
  //     ...defaultHeaderConfig,
  //     home: false,
  //     logo: true,
  //   },
  //   id: 'root',
  // },
  {
    Path: '/service-booking-listing',
    Component: React.lazy(() => import('../container/ServiceBookings')),
    meta: {
      name: 'Service Booking page',
    },
    headerConfig: {
      ...defaultHeaderConfig,
      home: false,
      logo: true,
    },
    id: 'service-booking-listing',
  },
  // {
  //   Path: '/ondemand-bookings',
  //   Component: React.lazy(() => import('../container/OnDemandBookings')),
  //   meta: {
  //     name: 'Ondemand Bookings',
  //   },
  //   headerConfig: {
  //     ...defaultHeaderConfig,
  //     home: false,
  //     logo: true,
  //   },
  //   id: 'ondemand-bookings',
  // },
];

export const publicRoutes = [
  {
    Path: '/login',
    Component: React.lazy(() => import('../container/Login')),
    meta: {
      name: 'Login page',
    },
    headerConfig: {
      ...defaultHeaderConfig,
      home: false,
      logo: true,
    },
    id: 'login',
  },
  {
    Path: '/policies-and-conditions/:title',
    Component: React.lazy(() => import('../container/PoliciesAndConditions')),
    meta: {
      name: 'TnC page',
    },
    headerConfig: {
      ...defaultHeaderConfig,
      home: false,
      logo: false,
    },
    id: 'tnc',
  },
];

export default { privateRoutes, publicRoutes };
