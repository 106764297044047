import React, { useEffect } from 'react';
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import Styles from './index.style';
import successIcon from '../../../assets/images/successIcon.svg';

function AlertMessage({
  variantType,
  setShowSnackbar,
  header,
  description,
  delay,
}) {
  useEffect(() => {
    setTimeout(() => {
      setShowSnackbar(false);
    }, delay);
  });

  return (
    <Styles>
      <div className="snackbar">
        {variantType !== 'default' && (
          <img src={successIcon} className="snackbar__icon" alt="success" />
        )}
        <div className="snackbar__wrapper">
          <h1>{header}</h1>
          {description !== '' && <p>{description}</p>}
        </div>
        <CloseIcon
          className="snackbar__close-icon"
          onClick={() => setShowSnackbar(false)}
        />
      </div>
    </Styles>
  );
}

AlertMessage.defaultProps = {
  setShowSnackbar: () => null,
  variantType: 'default',
  header: '',
  description: '',
  delay: 5000,
};
AlertMessage.propTypes = {
  setShowSnackbar: PropTypes.func,
  variantType: PropTypes.string,
  header: PropTypes.string,
  description: PropTypes.string,
  delay: PropTypes.number,
};

export default AlertMessage;
