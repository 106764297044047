import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import SidebarComponent from './sidebar.style';
import sideBarMenuConfig from '../../../constant/SidebarMenuConfig';
import Images from '../../../assets/images/index';
import { useAccessControl } from '../../../helpers/hooks';

export default function Sidebar({ toggleSideBar, sideBarWidth, toggleAction }) {
  const navigate = useNavigate();
  const {
    Wrapper,
    Divider,
    Container,
    MenuList,
    MenuItem,
    Label,
    Icon,
    MenuToggler,
  } = SidebarComponent;

  const { hamburgerToggler } = Images;

  const [menuList, setMenuList] = useState([...sideBarMenuConfig]);
  const accessControl = useAccessControl();

  const handleMenuClick = ({ id, route }) => {
    const menuListArr = JSON.parse(JSON.stringify(sideBarMenuConfig)); // Deep copying elements due to reference
    menuListArr.find((i) => i.id === id).isActive = true;
    setMenuList([...menuListArr]);
    if (menuListArr.find((i) => i.id === id).isActive) navigate(route);
  };
  return (
    <Wrapper toggleSidebar={toggleSideBar} sideBarWidth={sideBarWidth}>
      <MenuToggler
        open={toggleSideBar}
        src={hamburgerToggler}
        alt="hamburgertoggler"
        onClick={() => {
          toggleAction(!toggleSideBar);
        }}
      />
      <Container toggleSidebar={toggleSideBar} sideBarWidth={sideBarWidth}>
        <MenuList>
          {menuList
            .filter(({ roles }) => accessControl(roles))
            .map((i, idx) => {
              return (
                <React.Fragment key={`menu-item__${idx + 1}`}>
                  <MenuItem
                    onClick={() => handleMenuClick(i)}
                    className={`${!i?.route ? 'disabled' : ''}`}
                  >
                    <Icon
                      src={i.icon}
                      width="21px"
                      height="24px"
                      alt={i.title}
                    />
                    {toggleSideBar && (
                      <Label
                        active={
                          i.isActive || window.location.pathname === i.route
                        } // Window.location persists active menu during page refresh
                      >
                        {i.title}
                      </Label>
                    )}
                  </MenuItem>
                  {idx !== sideBarMenuConfig.length - 1 && <Divider />}
                </React.Fragment>
              );
            })}
        </MenuList>
      </Container>
    </Wrapper>
  );
}

Sidebar.propTypes = {
  toggleSideBar: PropTypes.bool,
  toggleAction: PropTypes.func,
  sideBarWidth: PropTypes.string,
};

Sidebar.defaultProps = {
  toggleSideBar: true,
  sideBarWidth: '311px',
  toggleAction: () => {},
};
