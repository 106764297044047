import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Header from './header/Header';
import LayoutComponent from './layout.style';
import Sidebar from './sidebar/Sidebar';

export default function Layout({ children, ...rest }) {
  const { Body, Children } = LayoutComponent;
  const [toggleMenu, setToggleMenu] = useState(true);

  const sideBarWidth = toggleMenu ? '250px' : '70px';

  return (
    <>
      <Header {...rest} />
      <Body>
        {rest.isPublicRoute && (
          <Sidebar
            sideBarWidth={sideBarWidth}
            toggleSideBar={toggleMenu}
            toggleAction={setToggleMenu}
          />
        )}
        <Children
          sideBarWidth={sideBarWidth}
          toggleSideBar={toggleMenu}
          sideBar={rest.isPublicRoute}
        >
          {children}
        </Children>
      </Body>
    </>
  );
}

Layout.propTypes = {
  /**
   * Wrapped JSX element will be displayed
   */
  children: PropTypes.node,
};

Layout.defaultProps = {
  children: {},
};
