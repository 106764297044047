import styled from 'styled-components';

export default styled.div`
  .typography {
    padding: 0;
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: var(--color-primary-grey-100);
    letter-spacing: -0.005em;
  }
  .typography--variant-h1 {
    font-weight: 900;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -0.03em;
  }
  .typography--variant-h2 {
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.03em;
  }
  .typography--variant-h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
  }
  .typography--variant-h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
  }
  .typography--variant-h5 {
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.005em;
  }
  .typography--variant-h6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.005em;
  }
  .typography--variant-subH1 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.005em;
  }
  .typography--variant-subH2 {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.005em;
  }
  .typography--variant-subH3 {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
  }
  .typography--variant-body1 {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
  }
  .typography--variant-body2 {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.005em;
  }
  .typography--color-primary {
    color: var(--color-primary-grey-80);
  }

  .typography--color-error {
    color: var(--color-primary-red);
  }

  .typography--color-white {
    color: var(--color-primary-inverse);
  }

  .typography--color-red {
    color: var(--color-primary-red);
  }

  .typography--color-blue {
    color: var(--color-primary-60);
  }
`;
