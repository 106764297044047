import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: [],
  requestStatus: {
    show: true,
    header: '',
    description: '',
    error: false,
  },
};

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.loading.push(1);
    },
    stopLoader: (state) => {
      state.loading.pop();
    },
    setRequestStatus: (state, action) => {
      state.requestStatus = action.payload;
    },
  },
});

export const { showLoader, stopLoader, setRequestStatus } =
  generalSlice.actions;

const generalReducer = generalSlice.reducer;

export default generalReducer;
