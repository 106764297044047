import { showLoader, stopLoader } from '../redux/slices/generalSlice';

export const getUserDetails = () => async (dispatch) => {
  try {
    dispatch(showLoader());
    dispatch(stopLoader());
    return true;
  } catch (e) {
    dispatch(stopLoader());
    return e.response;
  }
};

export default { getUserDetails };
