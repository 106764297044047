import { GOOGLE_EVENTS } from './constant';
import { triggerGAEvent } from './general';

const {
  LOGIN,
  SUCCESSFUL,
  FAILED,
  AUTH,
  LOGOUT,
  TAB,
  USAGE,
  SEARCH,
  FILTER,
  REFRESH,
  OPEN,
  TYPE,
  CLOSE,
  PAGE_COUNT,
  PAGINATION,
  PAGE_CHANGE,
  OTP,
  EXCEED,
  UNAUTHORIZED,
  ACCESS,
} = GOOGLE_EVENTS;

/* Google Analytics custom events */

// Auth events

export const loginSuccessEvent = () =>
  triggerGAEvent(`${SUCCESSFUL} ${LOGIN}`, AUTH, LOGIN);

export const loginFailEvent = () =>
  triggerGAEvent(`${FAILED} ${LOGIN}`, AUTH, LOGIN);

export const logoutSuccessEvent = () =>
  triggerGAEvent(`${SUCCESSFUL} ${LOGOUT}`, AUTH, LOGIN);

export const logoutFailEvent = () =>
  triggerGAEvent(`${FAILED} ${LOGOUT}`, AUTH, LOGIN);

export const otpExceededEvent = () =>
  triggerGAEvent(`${OTP} ${EXCEED}`, AUTH, LOGIN);

export const unAuthorizedEvent = () =>
  triggerGAEvent(`${UNAUTHORIZED} ${ACCESS}`, AUTH, LOGIN);

// Search Events

export const searchEvent = (searchLabel) =>
  triggerGAEvent(`${SEARCH} ${USAGE}`, SEARCH, searchLabel);

// Tab Events

export const tabEvent = (tabLabel) =>
  triggerGAEvent(`${TAB} ${USAGE}`, TAB, tabLabel, PAGE_CHANGE);

// Filter Events

export const filterEvent = () =>
  triggerGAEvent(`${FILTER} ${USAGE}`, FILTER, FILTER);

export const openFilterEvent = () =>
  triggerGAEvent(`${OPEN} ${FILTER} ${USAGE}`, FILTER, FILTER);

export const closeFilterEvent = () =>
  triggerGAEvent(`${CLOSE} ${FILTER} ${USAGE}`, FILTER, FILTER);

export const filterTypeEvent = (type, value) =>
  triggerGAEvent(`${FILTER} ${TYPE} ${USAGE}`, FILTER, type, value);

// Refresh Events

export const refreshEvent = () =>
  triggerGAEvent(`${REFRESH} ${USAGE}`, REFRESH, REFRESH);

// Pagination Events

export const perPageCountEvent = (count) =>
  triggerGAEvent(PAGE_COUNT, PAGINATION, count, count);

export default {
  loginSuccessEvent,
  loginFailEvent,
  logoutSuccessEvent,
  logoutFailEvent,
  filterEvent,
  refreshEvent,
  openFilterEvent,
  closeFilterEvent,
  tabEvent,
  searchEvent,
  filterTypeEvent,
  perPageCountEvent,
  otpExceededEvent,
  unAuthorizedEvent,
};
