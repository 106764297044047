import styled from '@emotion/styled';
import { device } from '../../styles/media';

const Body = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 72px;
  height: calc(100vh - 72px);
`;
const Children = styled('div')`
  width: 100%;
  height: 100%;
  @media ${device.laptop} {
    width: ${(props) => `calc(100vw - ${props.sideBarWidth})`};
    margin-left: ${(props) => (props.sideBar ? props.sideBarWidth : '0px')};
  }
`;
const LayoutComponent = {
  Body,
  Children,
};

export default LayoutComponent;
