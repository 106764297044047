import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ButtonBase, Popover } from '@mui/material';
import PropTypes from 'prop-types';
import { logout } from '../../../api/auth';
import Avatar from '../../common/Avatar';
import HeaderComponent from './header.style';
import { DEFAULT_LANDING_ROUTE } from '../../../helpers/constant';
import CustomLink from '../../common/CustomLink';
import Typography from '../../common/Typography/Typography';

export default function Header({
  logo,
  profilePicture,
  isPublicRoute,
  logoTag,
}) {
  const { Wrapper, Container, Profile, MenuItem } = HeaderComponent;
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    const onSuccess = () => {
      navigate('/login');
    };
    dispatch(logout(onSuccess));
  };

  const open = Boolean(anchorEl);
  const id = open ? 'profile-popover' : undefined;

  return (
    <Wrapper>
      <Container>
        <div className="flex-center-center">
          <CustomLink to={DEFAULT_LANDING_ROUTE()}>
            <Avatar hoverable size="sm" src={logo} />
          </CustomLink>
          <Typography color="white" variant="h5">
            {logoTag}
          </Typography>
        </div>
        {isPublicRoute && (
          <Profile onClick={handleClick}>
            <Avatar hoverable size="sm" src={profilePicture} />
          </Profile>
        )}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <ButtonBase onClick={handleLogout}>
            <MenuItem>Log Out</MenuItem>
          </ButtonBase>
        </Popover>
      </Container>
    </Wrapper>
  );
}

Header.propTypes = {
  /**
   * Application logo can be passed
   */
  logo: PropTypes.string,
  /**
   * Logged in user profile picture for avatar
   */
  profilePicture: PropTypes.string,
  /**
   * To show only logo on navbar when screen is in login/signup screen
   */
  isPublicRoute: PropTypes.bool,

  logoTag: PropTypes.string,
};

Header.defaultProps = {
  logo: '',
  profilePicture: '',
  isPublicRoute: false,
  logoTag: 'Service Center Back Office',
};
