import styled from '@emotion/styled';

const sizeMapping = {
  sm: '40px',
  md: '48px',
  lg: '56px',
  xl: '160px',
};

const fontMapping = {
  sm: '16px',
  md: '18px',
  lg: '20px',
  xl: '32px',
};

export const AvatarInitialStyle = styled('div')`
  color: ${(props) =>
    props.color ? props.color : 'var( --color-font-dark-green)'};

  background: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : 'var(--color-background-light-green)'};
  font-size: ${(props) => fontMapping[props.size]};
  height: ${(props) => sizeMapping[props.size]};
  width: ${(props) => sizeMapping[props.size]};
  border-radius: 50%;
  font-weight: 500;
  line-height: 24px;
  font-family: 'JioType';
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.hoverable ? 'pointer' : 'default')};
`;

export const AvatarSubContainer = styled('div')`
  background-color: var(--color-secondary-default-grey);
  height: ${(props) => sizeMapping[props.size]};
  width: ${(props) => sizeMapping[props.size]};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: ${(props) => (props.hoverable ? 'pointer' : 'default')};
`;
