import styled from '@emotion/styled';
import { Typography } from '@mui/material';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 2vw;

  position: fixed;
  height: 72px;
  left: 0;
  right: 0;
  top: 0;
  background: var(--color-primary-50);
  z-index: 9;
`;
const Container = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
const Profile = styled('span')``;
const MenuItem = styled(Typography)`
  cursor: pointer;
  padding: 12px 16px;
  &:hover {
    background: var(--color-header-menu-hover);
  }
`;
const HeaderComponent = {
  Wrapper,
  Container,
  Profile,
  MenuItem,
};

export default HeaderComponent;
