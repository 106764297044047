import { combineReducers, configureStore } from '@reduxjs/toolkit';
import generalReducer from '../slices/generalSlice';
import serviceBookingsReducer from '../slices/serviceBookingsSlice';
import userReducer from '../slices/userSlice';

const rootReducer = combineReducers({
  general: generalReducer,
  serviceBookings: serviceBookingsReducer,
  user: userReducer,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
