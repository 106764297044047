import { showLoader, stopLoader } from '../redux/slices/generalSlice';
import Api, { AuthApi } from '.';
import ApiEndPoint from './ApiEndpoint';
import { deleteAllCookies, setCookie } from '../helpers/general';
import {
  loginSuccessEvent,
  loginFailEvent,
  logoutSuccessEvent,
  logoutFailEvent,
  otpExceededEvent,
  unAuthorizedEvent,
} from '../helpers/events';

export const sendOtp = (mobile) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const url = ApiEndPoint.Otp + ApiEndPoint.Send;
    const userObject = {
      mobile,
      country_code: '+91',
    };
    const { data } = await AuthApi.post(url, userObject);
    dispatch(stopLoader());
    return data;
  } catch (e) {
    const res = e.response;
    if (res.status === 401) unAuthorizedEvent();
    if (res.status === 403) otpExceededEvent();

    dispatch(stopLoader());
    return res;
  }
};

export const verifyOtp =
  (otpData, successMethod, errorMethod) => async (dispatch) => {
    try {
      dispatch(showLoader());
      const url = ApiEndPoint.Otp + ApiEndPoint.Verify;
      const body = { ...otpData };
      const {
        data: { data },
      } = await AuthApi.post(url, body);
      setCookie('userAuth', 'true', 1);
      setCookie('userRoles', JSON.stringify(data), 1);
      loginSuccessEvent();
      successMethod();
      dispatch(stopLoader());
      return data;
    } catch (e) {
      const error = e.response;
      dispatch(stopLoader());
      if (error.status === 401) unAuthorizedEvent();
      loginFailEvent();
      errorMethod(
        error?.data?.data?.errorMessage ??
          'Something went wrong. Please try again..!',
      );
      return error;
    }
  };

export const logout =
  (successMethod = () => null) =>
  async (dispatch) => {
    try {
      dispatch(showLoader());
      const url = ApiEndPoint.Auth + ApiEndPoint.User + ApiEndPoint.Logout;
      const { data } = await Api.post(url);
      logoutSuccessEvent();
      setCookie('userAuth', 'false', -1);
      deleteAllCookies();
      successMethod();
      dispatch(stopLoader());
      return data;
    } catch (e) {
      dispatch(stopLoader());
      logoutFailEvent();
      return e.response;
    }
  };

export default { sendOtp, verifyOtp, logout };
