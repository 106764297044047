import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Styled from './Typography.style';

export default function Typography({
  variant,
  color,
  fontStyles,
  fontWeights,
  className,
  children,
  ...rest
}) {
  const variantsMapping = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    subH1: 'h6',
    subH2: 'h6',
    subH3: 'h6',
    body1: 'p',
    body2: 'p',
  };
  const Component = variant ? variantsMapping[variant] : 'p';
  return (
    <Styled className={className}>
      <Component
        style={{
          fontStyle: fontStyles,
          fontWeight: fontWeights,
        }}
        className={cn(
          {
            [`typography--variant-${variant}`]: variant,
            [`typography--color-${color}`]: color,
          },
          'typography',
        )}
        {...rest}
      >
        {children}
      </Component>
    </Styled>
  );
}

Typography.defaultProps = {
  variant: 'p',
  color: 'var(--color-primary-grey-100)',
  children: '',
  fontStyles: '',
  fontWeights: '',
  className: '',
};

Typography.propTypes = {
  /**
   * Types of tags
   */
  variant: PropTypes.string,
  /**
   * Types of colors in string format ('error', 'red','blue','white', 'primary')
   */
  color: PropTypes.string,
  /**
   * content of tags
   */
  children: PropTypes.any,
  fontStyles: PropTypes.string,
  fontWeights: PropTypes.string,
  className: PropTypes.string,
};
