import Images from '../assets/images/index';
import { USER_ROLES } from '../helpers/constant';

const { serviceBookings } = Images;
const sideBarMenuConfig = [
  {
    id: 1,
    title: 'Service Bookings',
    icon: serviceBookings,
    route: '/service-booking-listing',
    isActive: false,
    roles: USER_ROLES.USER,
  },
  // {
  //   id: 2,
  //   title: 'OnDemand Bookings',
  //   icon: serviceBookings,
  //   route: '/ondemand-bookings',
  //   isActive: false,
  //   roles: USER_ROLES.USER,
  // },
];

export default sideBarMenuConfig;
