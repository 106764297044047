import styled from '@emotion/styled';
import { device } from '../../../styles/media';

const appBarHeight = 72;

// const screenHeight = window.innerHeight - appBarHeight;

const Wrapper = styled('div')`
  position: fixed;
  left: 0;
  bottom: 0;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  height: 76px;
  background: var(--color-primary-background);
  z-index: 2;
  transition: width 400ms;
  @media ${device.laptop} {
    display: flex;
    top: ${appBarHeight}px;
    width: ${(props) => props.sideBarWidth};
    height: 100%;
  }
`;
const Container = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;

  @media ${device.laptop} {
    align-items: flex-start;
  }
`;
const Divider = styled('div')`
  height: 1px;
  width: 100%;
  background: var(--color-primary-grey-40);
  border-radius: 80px;
  display: none;

  @media ${device.laptop} {
    display: block;
  }
`;
const MenuList = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: row;

  @media ${device.laptop} {
    max-height: 92%;
    overflow-y: auto;
    box-sizing: border-box;
    overflow-x: hidden;
    flex-direction: column;
    padding: 38px 20px;
  }
`;

const MenuItem = styled('div')`
  display: flex;
  align-items: center;
  letter-spacing: -0.005em;
  line-height: 24px;
  cursor: pointer;
  flex-direction: column;
  width: 50%;

  @media ${device.laptop} {
    padding: 16px 0px;
    flex-direction: row;
    width: auto;
  }
`;
const Label = styled('span')`
  font-style: normal;
  font-weight: 500;
  color: ${(props) =>
    props.active ? 'var(--color-primary-60)' : 'var(--color-primary-grey-80)'};
  &: hover {
    color: var(--color-primary-60);
  }
  white-space: nowrap;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.005em;
  text-align: center;

  @media ${device.laptop} {
    font-size: 16px;
    padding-left: 20px;
  }
`;
const Icon = styled('img')``;

const MenuToggler = styled('img')`
  position: absolute;
  right: -10px;
  top: 20px;
  cursor: pointer;
  transform: ${(props) => (props.open ? 'rotate(180deg)' : 'rotate(0deg)')};
  display: none;

  @media ${device.laptop} {
    display: block;
  }
`;

const SidebarComponent = {
  Wrapper,
  Container,
  Divider,
  MenuList,
  MenuItem,
  Label,
  Icon,
  MenuToggler,
};

export default SidebarComponent;
