import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const CustomLink = ({ children, to, condition }) => {
  const { pathname } = useLocation();

  if (condition || pathname === to) {
    return children;
  }
  return <Link to={to}>{children}</Link>;
};

CustomLink.defaultProps = {
  children: null,
  to: '/',
  condition: false,
};

CustomLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  condition: PropTypes.bool,
};

export default CustomLink;
