import styled from 'styled-components';

export default styled.div`
  .snackbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 2;
    left: 0;
    bottom: 0;
    max-width: 388px;
    background-color: rgba(0, 0, 0, 0.65);
    border-radius: 24px;
    color: white;
    margin: 24px;
    padding: 16px 19px;
    gap: 15px;
    .snackbar__icon {
      padding-right: 0px;
      font-size: 25px;
    }
    .snackbar__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    h1 {
      font-size: 16px;
      margin: 2px;
      line-height: 25px;
    }
    p {
      font-size: 12px;
      margin: 2px;
    }
  }
`;
