export const regexSet = {
  Number_Regex: /^[0-9]*$/,
  SpecialChar_Regex: /^[\w&#_ -]+$/,
  Phone_Regex: /^(0|91)?[6-9][0-9]{9}$/,
  Email_Regex:
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export function isDesktop() {
  console.log(window.screen.width);
  if (window && window.screen.width >= 1200) {
    return true;
  }
  return false;
}

export default { regexSet, isDesktop };
