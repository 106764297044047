import React from 'react';
import './style.scss';
import PropTypes from 'prop-types';
import defaultImage from '../../../assets/images/defaultImage.svg';
import { AvatarInitialStyle, AvatarSubContainer } from './Avatar.style';

export default function Avatar({
  src,
  name,
  color,
  backgroundColor,
  size,
  hoverable,
}) {
  const customisedAvatar = () => {
    if (src) {
      return (
        <AvatarSubContainer hoverable={hoverable} size={size}>
          <img className="avatar__image__container" src={src} alt="avatar" />
        </AvatarSubContainer>
      );
    }

    if (name) {
      const parts = name.split(' ');

      const nameLessThanOne = parts[0][1]
        ? parts[0][0] + parts[0][1]
        : parts[0][0];

      const initials =
        parts.length > 1
          ? parts[0][0] + parts[parts.length - 1][0]
          : nameLessThanOne;

      if (initials.length > 1) {
        return (
          <AvatarInitialStyle
            hoverable={hoverable}
            size={size}
            color={color}
            backgroundColor={backgroundColor}
          >
            <div>{initials.toUpperCase()}</div>
          </AvatarInitialStyle>
        );
      }

      return (
        <AvatarInitialStyle
          hoverable={hoverable}
          size={size}
          color={color}
          backgroundColor={backgroundColor}
        >
          <div>{initials.toUpperCase()}</div>
        </AvatarInitialStyle>
      );
    }
    return (
      <AvatarSubContainer hoverable={hoverable} size={size}>
        <img src={defaultImage} alt="default" width="21px" height="25px" />
      </AvatarSubContainer>
    );
  };

  return <div className="avatar__container">{customisedAvatar()}</div>;
}

Avatar.propTypes = {
  /**
   * src could be either the url or local image that will be displayed as avatar
   */
  src: PropTypes.string,
  /**
   * name would give the initials as avatar
   */
  name: PropTypes.string,
  /**
   * color and backgroundColor would be the color and backgroundColor of the initials respectively
   */
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  /**
   * size is the dimensions of the avatar
   */
  size: PropTypes.string,
  /**
   * To enable cursor pointer on hover
   */
  hoverable: PropTypes.bool,
};

Avatar.defaultProps = {
  src: '',
  name: '',
  size: 'sm',
  color: 'var( --color-font-dark-green)',
  backgroundColor: 'var(--color-background-light-green)',
  hoverable: false,
};
