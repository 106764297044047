export default class ApiEndPoint {
  static Otp = '/otp';

  static Send = '/send';

  static Verify = '/verify';

  static Auth = '/auth';

  static Api = '/api';

  static User = '/user';

  static Logout = '/logout';

  static Crmlite = '/crmlite';

  static Filters = '/filters';

  static All = '/all';

  static Order = '/order';

  static Search = '/search';

  static Ondemand = '/ondemand';

  static Download = '/download';

  static sbpReport = '/sbp-report';

  static Sbp = '/sbp';

  static Orders = '/orders';
}
