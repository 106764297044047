import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  serviceBookingsData: {},
};

export const serviceBookingsSlice = createSlice({
  name: 'serviceBookings',
  initialState,
  reducers: {
    setFilteredData: (state, action) => {
      state.serviceBookingsData = action.payload;
    },
  },
});

export const { setFilteredData } = serviceBookingsSlice.actions;

const serviceBookingsReducer = serviceBookingsSlice.reducer;

export default serviceBookingsReducer;
